<script>
import ConfirmationModal from './ConfirmationModal.vue'
import UserAvatarInitials from '@/components/general/UserAvatarInitials'
export default {
  components: { ConfirmationModal, UserAvatarInitials },
  name: 'UserListItem',
  props: {
    user: {
      type: Object
    }
  },
  data () {
    return {
      showConfirmationModal: false,
      showConfirmationReactivateModal: false
    }
  },
  computed: {
    disableChangeRole () {
      const user = this.$store.getters.getUser
      return user.id === this.user.id
    }
  },
  methods: {
    removeUser (userId) {
      this.showConfirmationModal = false
      this.$store.dispatch('attemptDeleteUser', { id: userId, active: false })
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.admincenter.users.labels:remove.success')
          })
          this.$emit('userDeleted')
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('settings.admincenter.users.labels:remove.error')
          })
        })
    },
    reactivateUser (userId) {
      this.showConfirmationReactivateModal = false
      this.$store.dispatch('attemptReactivateUser', { id: userId, active: true })
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.admincenter.users.labels:reactivate.success')
          })
          this.$emit('userReactivated')
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('settings.admincenter.users.labels:reactivate.error')
          })
        })
    },
    viewUserProfile () {
      this.$router.push({ name: 'business.admin.center.users.profile', params: { userId: this.user.id } })
    }
  }
}
</script>
<template>
  <div class="user-list-item--wrapper">
    <div class="user-list-item--left">
      <v-avatar size="40px" class="mr-4">
        <v-img v-if="!!user.avatarImage && !(user.avatarImage.includes('/profile-avatar.jpg') || user.avatarImage.includes('/empty'))" height="40px" width="40px" :src="user.avatarImage"></v-img>
        <user-avatar-initials v-else :user="user" :size="40"/>
      </v-avatar>
      <div class="user-list-item--info">
        <div class="user-list-item--upper-info-wrapper">
          <p>{{ user.name }}</p>
          <div v-if="user.status !== 'waiting.first.access'">
          <div class="user-list-item--status" v-if="$te(`user.list.item:status.${user.status}`) && user.active">
            <div class="icon-wrapper">
              <v-icon size="12px" color="#fff">mdi-dots-horizontal</v-icon>
            </div>
            <span>{{ $t(`user.list.item:status.${user.status}`) }}</span>
          </div>
        </div>
          <div class="user-list-item--email" v-if="!user.active">
            <span>{{ user.email }}</span>
          </div>
        </div>
        <span>{{ $t(`modal.invite.member:roles.${user.role}`) }}</span>
      </div>
    </div>
    <div class="user-list-item--right">
      <v-btn class="btn" color="#fff" v-if="user.active" @click="viewUserProfile()"><v-icon size="18" :color="getPrimaryColor">mdi-eye</v-icon></v-btn>
      <v-btn class="btn ml-4" color="#fff" v-if="user.active && !disableChangeRole" @click="showConfirmationModal = true" ><v-icon size="18" :color="getPrimaryColor">mdi-delete</v-icon></v-btn>
      <v-btn class="btn ml-4" color="#fff" v-if="!user.active" @click="showConfirmationReactivateModal = true"><v-icon size="18" :color="getPrimaryColor">mdi-undo-variant</v-icon></v-btn>
    </div>
    <confirmation-modal
      :show="showConfirmationModal"
      :title="$t('user.list.item:remove.title', { name: user.name })"
      :description="$t('user.list.item:remove.description', { name: user.name, email: user.email })"
      :confirmText="$t('global:remove')"
      :btnConfirmText="false"
      @confirm="removeUser(user.id)"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationModal = false"
    />
    <confirmation-modal
      :show="showConfirmationReactivateModal"
      :title="$t('user.list.item:reactivate.title', { name: user.name })"
      :description="$t('user.list.item:reactivate.description', { name: user.name, email: user.email })"
      :confirmText="$t('global:reactivate')"
      :btnConfirmText="false"
      @confirm="reactivateUser(user.id)"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationReactivateModal = false"
    />
  </div>
</template>
<style lang="scss">
.user-list-item--wrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 20px;
  .user-list-item--left {
    display: flex;
    align-items: center;
    height: 40px;
    .user-list-item--info {
      text-align: left;
      .user-list-item--upper-info-wrapper {
        display: flex;
        align-items: center;
        .user-list-item--status {
          display: flex;
          align-items: center;
          background: linear-gradient(0deg, rgba(243, 170, 24, 0.1), rgba(243, 170, 24, 0.1)), #FFFFFF;
          border-radius: 100px;
          padding: 4px 8px;
          margin-left: 8px;
          .icon-wrapper {
            height: 14px;
            width: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F3AA18;
            margin-right: 8px;
            border-radius: 100px;
          }
          span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 12px;
            color: #F3AA18;
          }
        }
        p {
          margin-bottom: 0px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #212121;
        }
        .user-list-item--email {
          background: linear-gradient(0deg, rgba(160, 160, 160, 0.1), rgba(160, 160, 160, 0.1)), #FAFAFA;
          border-radius: 900px;
          padding: 2px 8px;
          margin-left: 8px;
          span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 12px;
            color: #A0A0A0;
          }
        }
      }
      span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #A0A0A0;
      }
    }
  }
  .user-list-item--right {
    display: flex;
    align-items: center;
    .v-btn {
      height: 30px;
      max-height: 30px;
      width: 30px;
      min-width: unset;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
